export default {
  primaryWhite: '#FFFFFF',
  primaryBlack: '#141F26',
  primaryRed: '#E22028',
  secondaryDark: '#113C42',
  primaryGray: '#dcdcdc',
  secondaryGray: '#707070',
  redBorder: '#EF323A',
  inputLabelColor: '#818181',
  dropdownButtonArrowColor: '#535353',
  descriptionBackground: '#EDEDED',
  successGreen: '#228B22',
};
