// import i18next from 'i18next'
// import { get } from 'lodash'
// class Locales {
//
//   static init() {
//     i18next.init({
//         lng: 'pl',
//         debug: true,
//         resources: {
//           ...locales
//         }
//       },
//       error => {
//         if (error) {
//           console.error(error)
//         }
//       })
//   }
//
//   static has(key) {
//     return i18next.exists(key)
//   }
//
// }
//
// const t = (key, options) => {
//   const { language } = i18next
//   const translations = i18next.getDataByLanguage(language)
//   const str = get(translations, key)
//   console.log(key, str)
//   return str
// }
//
// export {
//   Locales,
//   t
// }

import i18next from 'i18next';
import constants from "./constants";

let initialLanguage = constants.COMPILATION_FALLBACK_LANGUAGE;

if (typeof window !== 'undefined') {
  initialLanguage = String(window.location.pathname).indexOf('/en') === 0 ? 'en' : 'pl';
}

i18next.init({
  lng: initialLanguage,
  resources: {
    pl: {
      translations: require('./locales/pl.json')
    },
    en: {
      translations: require('./locales/en.json')
    }
  },
  ns: ['translations'],
  defaultNS: 'translations',
  returnObjects: true,
  debug: process.env.NODE_ENV === 'development',
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  react: {
    wait: true,
  },
});

i18next.languages = ['pl', 'en'];

export default i18next;
