const font = {
  weight: {
    s: 300,
    m: 400,
    l: 500,
    xl: 600,
    xxl: 700,
  },
  size: {
    xs: '0.8em',
    s: '1em',
    m: '1.2em',
    l: '1.6em',
    xl: '2em',
    xxl: '2.5em',
    xxxl: '5.5em',
    huge: '12em',
  },
};

export default font;
