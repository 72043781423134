export default {
  consents: {
    RODO: 'RODO',
    DATA_PROCESSING: 'DATA_PROCESSING',
    TRADE_INFO: 'TRADE_INFO',
    MOBILE_MARKETING: 'MOBILE_MARKETING',
    PRIVACY_POLICY: 'PRIVACY_POLICY',
  },
  dropdownOptions: {
    KRAKOW: 'Kraków',
    WARSZAWA: 'Warszawa',
    GDANSK: 'Gdańsk',
  },
  inputTypes: {
    TEXT: 'text',
    EMAIL: 'email',
    PHONE: 'phone'
  },
  inputErrors: {
    EMAIL: 'Podany tekst nie jest adresem email',
    PHONE: 'Niepoprawny numer telefonu',
    EMPTY: 'To pole nie może być puste',
  },
  EMAIL_REGEX: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PHONE_REGEX: /^[0-9\b]+$/,
  formStatuses: {
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR'
  },
  CONTACT_FORM_URL: 'https://7rcityflex.pl/send/index.php',
  REQUIRED_FIELDS: ['name', 'surname', 'email'],
  REQUIRED_CONSENTS: ['RODO'],
  LANGUAGES: ['pl', 'en'],
  LANGUAGE_DOMAINS: {
    pl: '7rcityflex.pl',
    en: '7rcityflex.pl/en/',
  },
  COMPILATION_FALLBACK_LANGUAGE: 'en',
};
