import { createGlobalStyle, css } from 'styled-components';
import AkkuratRegular from '../assets/fonts/AkkuratPro-Regular.otf';

const fontFaces = css`
  @font-face {
    font-family: Akkurat, Arial, sans-serif;
    src: url(${AkkuratRegular}) format('otf');
    font-style: normal;
  }
`;

const GlobalStyle = createGlobalStyle`
  ${fontFaces}
  *, *::before, *::after {
    font-family: Akkurat, Arial, sans-serif;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

  }

  html {
    font-size: 62.5%;
    max-width: 100vw;
    overflow-x: hidden;
  }

  body {
    padding: 0;
    margin: 0 !important;
    font-size: 1.6rem;
    max-width: 100%;
    overflow-x: hidden;
    position: relative;
    box-sizing: border-box;
  }

  main {
    width: 100vw;
  }

`;

export default GlobalStyle;
