import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from '../utils/GlobalStyle';
import theme from '../utils/theme';
import withTranslations from '../utils/withTranslations';

const Layout = ({ children }) => {

  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <div>
          <main>
            {children}
          </main>
        </div>
      </ThemeProvider>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withTranslations(Layout);
